import { Suspense, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ReactGA from 'react-ga4';
import NavBar from './components/NavBar/NavBar';
import Loading from './components/Loading';
import Footer from './components/Footer';
import View from './components/View';
import Modal from './components/Modal';
import Notification from './components/Notification';
import { GA_MEASUREMENT_ID, NONCE } from './utilities/constants';
import 'react-coinbase-commerce/dist/coinbase-commerce-button.css';
import './styles/helpers.css';
import './App.css';

const Loader = () => (
  <div className="App App--loading">
    <Loading message="Preparing RNG Royale" />
  </div>
);

function App() {
  const { isLoading } = useAuth0();

  useEffect(() => {
    ReactGA.initialize(GA_MEASUREMENT_ID, {
      nonce: NONCE,
    });
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <div className="App">
        <NavBar />
        <View />
        <Footer />
        <Notification />
        <Modal />
      </div>
    </Suspense>
  );
}

export default App;
